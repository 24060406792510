import { atobID } from '@lib/utils/tools'
const variantGetCoupon = ({
  coupons = null,
  variant,
  product,
  handle,
  channel,
}) => {
  if (coupons) {
    let couponsData =
      coupons?.[variant?.sku] || coupons?.[product?.handle] || []
    if (couponsData.length > 0) {
      return couponsData?.find((i) => i?.handle === handle) || {}
    } else if (coupons?.length > 0) {
      return (
        coupons?.find((i) => i?.sku === variant?.sku && i?.handle === handle) ||
        {}
      )
    }
  } else {
    const hideChannel = 'wsch'
    const discountCodeApp = variant?.metafields?.discountCodeApp || []

    const results = discountCodeApp?.filter((coupon) => {
      const isActive =
        (coupon?.ends_at === null ||
          new Date(coupon?.ends_at).getTime() > new Date().getTime()) &&
        new Date(coupon?.starts_at).getTime() <= new Date().getTime() &&
        coupon.variant_shopify_id === +atobID(variant.id)

      return (
        isActive &&
        (channel
          ? coupon?.title?.toLocaleLowerCase().includes(channel)
          : !coupon?.title?.toLocaleLowerCase().includes(hideChannel))
      )
    })

    return results?.[0]
  }
}

export default variantGetCoupon
